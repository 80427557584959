<template lang="html">
    <div>
      <div class="amber lighten-4 d-flex justify-space-between">
        <div class="d-flex pa-2">
          <v-avatar size="50">
            <v-img :src="pegawai.foto"></v-img>
          </v-avatar>
          <div class="ml-2">
            <div class="font-weight-medium orange--text text--darken-3">{{pegawai.nama}}</div>
            <div class="grey--text">{{pegawai.nip}}</div>
          </div>
        </div>
        <div class="pa-2 font-weight-medium orange--text text--darken-3">{{tanggal|formatTime}}</div>
      </div>
      <v-simple-table>
        <tbody>
          <template v-for="(item,i) in aktivitas">
            <tr :key="i">
              <td colspan="5" class="font-weight-medium">{{i+1}}. {{item.nama_aktifitas}}</td>
            </tr>
            <tr :key="i+'a'">
              <td><br/><b>{{item.jenis_skp}}</b> {{item.skp_ket}} <br/><br/>
              <b>Aktivitas:</b> {{item.keterangan}} <br/><br/>
                <b>Angka Kredit</b> {{item.angka_kredit || '-'}}<br/><br/>
              </td>
              <td>{{item.jam_mulai | addZero}}:{{item.menit_mulai | addZero}} -
                {{item.jam_selesai | addZero}}:{{item.menit_selesai | addZero}}
              </td>
              <td>{{item.volume}} x {{item.satuan_output}}</td>
              <td >
                <div v-if="item.status === 'valid'">
                  <v-chip color="success lighten-1">
                    <v-icon left>mdi-checkbox-marked-circle</v-icon>Terima
                  </v-chip>
                </div>
                <div v-else-if="item.status === 'invalid'">
                  <v-chip color="error lighten-1">
                    <v-icon left>mdi-close-circle</v-icon>Tolak
                  </v-chip>
                </div>
                <div v-else>
                  <text-btn @on-agree="simpan" title="Terima" :data="{...item, jenis:'terima'}" :btnProps="{name:'Terima', color:'success', icon:'mdi-check', small:true, class:{'ma-1':true} }" class="ma-1"></text-btn>
                  <text-btn @on-agree="simpan" title="Tolak" :data="{...item, jenis:'tolak'}" :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', small:true, class:{'ma-1':true} }" class="ma-1"></text-btn>
                </div>
              </td>
            </tr>
          </template>

        </tbody>

      </v-simple-table>
    </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import PenilaianService from '@/services/PenilaianService'
import moment from 'moment'
import TextBtn from './components/TextBtn'

export default {
  components:{
    TextBtn
  },

  props:{
    dataAktivitas:Array,
    tanggal:String,
    pegawai:Object,
  },

  data(){
    return {
      hides:{},
      aktivitas:[]
    }
  },

  filters:{
    addZero: function(value) {
      if (value * 1 < 10) {
        return '0' + value
      }
      return value
    },

    formatTime: function(value){
      moment.locale('id')
      return moment(value).format('dddd, Do MMMM YYYY');
    }
  },

  mounted(){
    this.$watch(vm=>[vm.tanggal, vm.pegawai], val =>{
      this.aktivitas = _.cloneDeep(this.dataAktivitas)
    },{
      immediate:true,
      deep:true,
    })
  },

  methods:{

    findId(isi_id){
      var i,j
      for (i=0;i < this.aktivitas.length; i++){
        let isi = this.aktivitas[i]
        if(isi.id == isi_id){
          return {isi: isi, i: i}
        }
        // let isi = this.aktivitas[i].isi_aktifitas
        // let isi = this.aktivitas[i]
        // for (j=0;j < isi.length; j++){
          // console.log(isi[j])
          // if(isi[j].id == isi_id){
          //   return {isi: isi[j], i: i,j:j}
          // }
        // }
      }
      return
    },
    simpan(ket){
      var data = ket.data

      if(data.jenis === 'terima'){
        PenilaianService.validasiAktivitasTerima(data.id,ket.ket,data.nip18).then(response=> {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
            this.findId(data.id).isi.status = 'valid'
          } else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
          }
          this.$emit('update')
        }).finally(()=>{
          this.hides[data.key] = true
        })

      }else if (ket.data.jenis === 'tolak'){
        PenilaianService.validasiAktivitasTolak(data.id,ket.ket,data.nip18).then(response=> {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
            this.findId(data.id).isi.status = 'invalid'
          } else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
          }
          this.$emit('update')
        })
      }

    }
  }
}
</script>

<style lang="css" scoped>
</style>
