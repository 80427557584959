import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const PenilaianService = {
  getValidasiCalendar,
  getValidasiTampil,
  validasiAktivitasTerima,
  validasiAktivitasTolak
}

function getValidasiCalendar({from, to}){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-value-validasi-aktivitas-calendar',
    method: 'post',
    data: {
      nip: current.username,
      from: from,
      to: to
    }
  })
}

function getValidasiTampil(tanggal, jenis){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-validasi-aktivitas-tampil',
    method: 'post',
    data: {
      nip: current.username,
      tanggal: tanggal,
      jenis: jenis
    }
  })
}


function validasiAktivitasTerima(id,ket,nip_bawahan){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/validasi-aktivitas-terima-validasi-aktivitas',
    method: 'post',
    data:{
      nip : current.username,
      "id": id,
      "nip_bawahan": nip_bawahan,
      "valid": "valid",
      "role": "valid",
      "keterangan_valid": ket
    }
  })
}


function validasiAktivitasTolak(id,ket,nip_bawahan){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/validasi-aktivitas-tolak-validasi-aktivitas',
    method: 'post',
    data:{
      nip : current.username,
      "id": id,
      "nip_bawahan": nip_bawahan,
      "valid": "invalid",
      "role": "valid",
      "keterangan_valid": ket
    }
  })
}

export default PenilaianService
