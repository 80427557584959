<template lang="html">
  <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="btnProps.color"
        dark
        v-bind="attrs"
        v-on="on"
        :class="btnProps.class"
        :small="btnProps.small"
        :x-small="btnProps.xSmall"
      >
        <v-icon v-if="btnProps.icon" :small="btnProps.small" :x-small="btnProps.xSmall" left >{{btnProps.icon}}</v-icon>
        {{btnProps.name}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{title}}
      </v-card-title>
      <v-card-text><v-textarea v-model="ket" counter="300" label="keterangan"></v-textarea></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="disagree()"
        >
          Batal
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="agree()"
        >
          Simpan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    title:String,
    content:String,
    data:Object,
    btnProps:{
      name:String,
      color:{
        type:String,
        default:'primary'
      }
    }
  },
  data(){
    return{
      dialog:false,
      ket:''
    }
  },
  methods:{
    agree(){
      this.dialog = false
      this.$emit('on-agree',{ket:this.ket, data:this.data})
    },
    disagree(){
      this.dialog = false
    }
  }

}
</script>

<style lang="css" scoped>
</style>
